import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web/build/player/lottie_light";
import FlymyaLoading from "../lotties/loading_animation.json";

const FlymyaIconLoading = (props) => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    const lottieInstance = Lottie.loadAnimation({
      container: lottieContainer.current, // The DOM element to render the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: FlymyaLoading,
    });

    return () => {
      lottieInstance.destroy(); // Cleanup on unmount
    };
  }, []);

  return (
    <div className="loadingGifDiv">
      <div ref={lottieContainer} style={{ width: "100px", height: "100px" }} />
      {props?.loadingMessage && (
        <p className="loadingMessage">{props?.loadingMessage}</p>
      )}
    </div>
  );
};

export default FlymyaIconLoading;
